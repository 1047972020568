<template>
    <div class="mail-table">
      <!-- User Table -->
      <el-table
        empty-text="Keine Emails"
        :data="tableData"
        style="width: 100%">
        <el-table-column
          class-name="id-column"
          prop="id"
          label="ID">
        </el-table-column>
        <el-table-column
          prop="receiver"
          label="To">
        </el-table-column>
        <el-table-column
          prop="subject"
          label="Subject">
        </el-table-column>
        <el-table-column
          prop="status"
          label="Status">
        </el-table-column>
        <el-table-column
          prop="sentAt"
          label="Sent">
        </el-table-column>
      </el-table>

      <div class="load-more">
        <el-button @click="$emit('loadmore')" :loading="loading">Mehr laden</el-button>
      </div>
    </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex';

export default {
  name: 'UserTable',
  props: ['items', 'loading', 'type'],
  data() {
    return {}
  },
  computed: {
    ...mapState({
      env: state => state.settings.env,
    }),
    tableData() {
      return this.items.map(item => ({
          id: `...${item._id.substr(-6)}`,
          receiver: item.receiver.email,
          subject: item.mail.subject,
          status: item.status + (item.error ? ` (${item.error})` : ''),
          sentAt: moment(item.createdAt).format('DD.MM.YYYY HH:mm'),
      }));
    }
  },
  methods: {}
}
</script>

<style lang="scss"> 
.mail-table {
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  padding: 25px;
  border: 1px solid rgb(240, 240, 240);
  border-radius: 18px;

  td.id-column .cell {
    white-space: nowrap;
    word-break: normal;
  }

  .load-more {
    margin-top: 40px;
    text-align: center;
  }
}
</style>
