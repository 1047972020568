<template>
  <div class="page">
    <div class="search"><el-input placeholder="Suche ..." v-model="search"></el-input></div>
    <MailTable @loadmore="loadmore" :items="items" :loading="loading" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MailTable from '@/components/MailTable'
import { FETCH_MAILS, LOAD_MORE_MAILS } from '@/vuex/modules/mails/actions';
let searchTimeout = null;

export default {
  name: 'Mails',
  data() {
    return {
      search: '',
    }
  },
  async created() {
      this.updateMails();
  },
  computed: {
    ...mapState({
      env: state => state.settings.env,
      items: state => state.mails.items,
      loading: state => state.mails.loading,
    }),
  },
  watch: {
    env() {
      this.updateMails()
    },
    search() {
      if (!searchTimeout) {
        clearTimeout(searchTimeout);
      }
      searchTimeout = setTimeout(() => {
        searchTimeout = null;
        this.updateMails();
      }, 800);
    }
  },
  methods: {
    updateMails() {
      this.$store.dispatch(FETCH_MAILS, {
        search: this.search
      })
    },
    loadmore() {
      this.$store.dispatch(LOAD_MORE_MAILS);
    }
  },
  components: {
    MailTable
  }
}
</script>

<style lang="scss" scoped>
  .search {
    margin: 20px;
  }
</style>
